import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Link from '../link';

const NavbarItemTitle = styled(Link)`
    ${({ theme }) => css`
        background: transparent;
        border: 0;
        font-weight: 500;
        font-family: inherit;
        font-size: 16px;
        padding: ${theme.global.edgeSize.small};
        color: #fff;
        display: flex;
        justify-content: center;
        transition: opacity 250ms;
        cursor: pointer;
        text-decoration: none;
        /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
        position: relative;
        z-index: 22;
        &.is-active {
            /* color: ${theme.global.colors.brand.light}; */
            color: #fff;
            font-weight: 700;
        }
        &:hover,
        &:focus {
            outline: none;
            color: ${theme.global.colors.brand.light};
            color: #fff;
        }
    `}
`;

const NavbarItemEl = styled.li`
    /* position: relative; */
`;

const DropdownSlot = styled.div`
    position: absolute;
    left: 0;
    right: 20px;
    z-index: 20;

    @media (min-width: 1400px) {
        left: 235px;
    }

    /* transform: translateX(-50%); */
    perspective: 1500px;
`;

interface Props {
    onMouseEnter: (any) => any;
    title: string;
    index: number;
    children: any;
    to: string;
}

const NavbarItem: FC<Props> = props => {
    const { title, to, children } = props;

    const onMouseEnter = () => {
        props.onMouseEnter(props.index);
    };

    return (
        <NavbarItemEl onMouseEnter={onMouseEnter} onFocus={onMouseEnter}>
            <NavbarItemTitle to={to} activeClassName="is-active" partiallyActive>
                {title}
            </NavbarItemTitle>
            <DropdownSlot>{children}</DropdownSlot>
        </NavbarItemEl>
    );
};
export default NavbarItem;
